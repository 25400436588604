import { Component, OnInit, inject } from '@angular/core';
import { TokenEntryComponent } from '../../../../common-ui/src/lib/components/token-entry/token-entry.component';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { MatDivider } from '@angular/material/divider';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
  selector: 'app-assessor-token-entry',
  standalone: true,
  imports: [
    MatIconModule,
    MatCardModule,
    MatProgressBar,
    MatDivider,
    NgIf
  ],
  templateUrl: '../../../../common-ui/src/lib/components/token-entry/token-entry.component.html',
  styleUrl: '../../../../common-ui/src/lib/components/token-entry/token-entry.component.scss'
})
export class AssessorTokenEntryComponent extends TokenEntryComponent {

  getAppName() {
    return 'Assessor Portal';
  }

}
