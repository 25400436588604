import { Routes } from '@angular/router';
import { AssessorTokenEntryComponent } from './assessor-token-entry/assessor-token-entry.component';

// Guide: https://angular.io/guide/router
// Nesting Children: https://www.angulararchitects.io/en/blog/routing-and-lazy-loading-with-standalone-components/
// Lazy Loading and Routing: https://www.angulararchitects.io/en/blog/routing-and-lazy-loading-with-standalone-components/
// Active Link Tracking: https://angular.io/api/router/RouterLinkActive

export const BASE_ROUTES: Routes = [
    {
        path: 'dashboard',
        loadChildren: () =>
            import('./dashboard/dashboard.routes')
                .then(x => x.DASHBOARD_ROUTES)
    },
    // This is the entry component that handles grabbing the token and such.
    {
        path: '',
        component: AssessorTokenEntryComponent,
        pathMatch: 'full'
    }
];